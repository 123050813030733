@use '../../scss/mixins' as *;

.ServiceProviderReportPage {
  min-height: 100vh;
  padding-bottom: 72px;

  @include onDesktop {
    padding-bottom: 0;
  }

  .tableContainer {
    margin-bottom: 40px;
    max-width: 100vw;
    overflow-x: auto;
    border-radius: 24px;

    @include onDesktop {
      width: calc(100vw - 650px);
    }

    .spNameTd {
      .spNameContainer {
        display: flex;
        align-items: center;

        .avatar {
          width: 30px;
          height: 30px;
          min-width: 30px;
          max-width: 30px;
        }
      }
    }

  }
}