@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.Menu {
  @include onDesktop {
    min-width: 300px;
    max-width: 300px;
    width: 100%;
  }

  .menuContainer {
    max-height: calc(100vh - 60px);
    overflow: auto;
    width: 250px;
    padding: 36px 28px;
    position: fixed;
    top: 60px;
    bottom: 0;
    transform: translateX(-100%);
    z-index: 10;
    flex-shrink: 0;
    border-right: 1px solid #c5c5c5;
    background: $color-background;

    @include onDesktop {
      width: 300px;
      padding: 40px 0 20px 44px;
      transform: translateX(0);
    }

    .logo {
      @include onDesktop {
        height: 54px;
        width: 180px;
        margin: 0 auto;
        display: flex;
      }
    }
    .creditsBadge {
      min-width: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 35px;
      background: #0a3d75;
      min-height: 40px;
      @include onDesktop {
      }
      .credits {
        display: block;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #e8eef0;

        @include onDesktop {
          display: none;
        }
      }
    }
    .list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      list-style: none;

      @include onDesktop {
        gap: 0;
      }

      .item {
        display: flex;
        position: relative;

        @include onDesktop {
          margin: 5px 15px 5px 0;
          z-index: 1;

          &:first-of-type {
            height: 0;
            padding: 0;
          }
        }

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          padding: 10px 10px 10px 35px;
          width: 100%;

          @include onDesktop {
            padding: 10px 10px 10px 50px;
          }

          .label {
            color: #161616;
            font-size: 14px;
            font-weight: 300;

            @include onDesktop {
              font-size: 14px;
              overflow: hidden;
            }
          }
        }

        &.active {
          background: #e3e3e3;

          .label {
            color: $color-primary;
          }
        }

        &.inValidPaymentProfile {
          .label {
            color: red;
          }
        }

        &.disableMessageMenu,
        &.disableMessageMenu span,
        &.disableMessageMenu * {
          cursor: not-allowed;
          color: #777;
        }

        .icon {
          display: flex;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translate(-50%, -50%);

          @include onDesktop {
            left: 30px;
            top: 60%;
            transform: scale(1.5) translate(-50%, -50%);
          }
        }

        .unreadMessageCount {
          position: absolute;
          right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 18px;
          min-height: 18px;
          padding: 3px 7px;
          font-size: 10px;
          border-radius: 50%;
          background-color: darkred;
          color: #f7f7f7;
        }
      }
    }

    .logoutButton {
      display: flex;
      height: 48px;
      align-items: center;
      justify-content: center;
      padding-right: 25px;
      margin-bottom: 15px;
    }

    .calloutContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: $color-primary;
      color: #f8f8f8;
      border-radius: 3px;
      padding: 13px;
      font-size: 14px;

      @include onDesktop {
        margin-right: 15px;
        font-size: 14px;
      }

      & > p:first-child {
        font-weight: bold;
      }

      & > p {
        margin: 0;
      }
    }
  }

  &.open {
    .menuContainer {
      transform: translate(0);
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(38, 42, 65, 0.3);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  z-index: 9;

  @include onDesktop {
    display: none;
  }
}
