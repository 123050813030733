@use '../../../../scss/_colors' as *;
@use '../../../../scss/mixins' as *;

.ProjectManager {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;

  @include onDesktop {
    gap: 12px;
  }

  .title {
    color: #161616;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.26px;

    @include onDesktop {
      font-size: 14px;
      letter-spacing: 0.32px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @include onDesktop {
      flex-direction: row;
    }

    .name {
      font-weight: 500;
    }

    .pmText {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 5px;

      @include onDesktop {
        align-items: center;
        flex-direction: row;
      }
    }

    .text {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      letter-spacing: 0.16px;
      color: #161616;
      font-style: normal;
      line-height: normal;

      @include onDesktop {
        gap: 8px;
        font-weight: inherit;
        letter-spacing: 0.32px;
      }

      .icon {
        width: 22px;
        height: 22px;
        background: #f3f2f2;
      }
    }

    .LinkButton {
      color: $color-primary;
      padding: 0 7px;
      text-decoration: none;
      font-size: 14px;
    }
  }
}
