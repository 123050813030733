@use '../../scss/mixins' as *;
@use '../../scss/colors' as *;

.Header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include onDesktop {
    height: 120px;
    padding: 30px 40px;

    .menuButton,
    .logo {
      display: none;
    }
  }

  button {
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .title {
    display: none;

    @include onDesktop {
      margin: 0;
      display: block;
      color: $color-secondary;
      font-size: 34px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.8px;
    }
  }
  .userBadgeContainer {
    display: flex;
    gap: 10px;
    @include onDesktop {
    }
    .creditsBadge {
      @include onDesktop {
        min-width: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        background: #0a3d75;
      }
      .credits {
        display: none;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        @include onDesktop {
          display: block;
          color: #e8eef0;
        }
      }
    }
    .userBadge {
      @include onDesktop {
        min-width: 170px;
        padding-left: 24px;
        display: flex;
        justify-content: space-between;
        border-radius: 35px;
        background: rgba(148, 150, 161, 0.21);
      }

      .greetingsContainer {
        @include onDesktop {
          margin-right: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }

        .greetings {
          display: none;

          @include onDesktop {
            display: block;
            color: $color-secondary;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .logoutButton {
          display: none;

          @include onDesktop {
            display: flex;
            align-items: center;
            color: #606161;
            font-size: 11px;
            letter-spacing: 1px;

            img {
              margin-right: 4px;
            }
          }
        }
      }

      .userAvatar {
        margin: 0;
        border: 1px solid $color-secondary;
      }
    }
  }
}
